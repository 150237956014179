/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/no-unescaped-entities */
// @ts-nocheck
import { CardPointToPoint, CardRecognitions, CardWithDescription, MetaData, PackageSwiperBig, StyledButton } from '@/components/atoms'
import route_list from '@/constant/routeUrl'
import { useRouter } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { background_list, icon_list } from '@/assets/images'
import { TitleImageDescSection } from '@/components/molecules'
import app_config from '@/constant/config'
import { getSeoMetadata } from '@/utility/apiProcess/seo/server'

const Homepage = (props) => {
	const { t } = useTranslation('homepage')
	const router = useRouter()
  const our_section = [
    {
      title: t('homepage.who_are_we'),
      description1: t('homepage.who_are_we_d1'),
      description2: t('homepage.who_are_we_d2'),
      image: background_list.why_we_1
    },
    {
      title: t('homepage.what_we_do'),
      description1: t('homepage.what_we_do_d1'),
      description2: t('homepage.what_we_do_d2'),
      image: background_list.why_we_2
    },
  ]

	const swiperData = [
    {
      title: "Starter Package",
      image: background_list.sila,
      onClick: () => {
        window.fbq('track', 'Add To Cart', {content_name: router.route})
        router.push(route_list.register)
      },
      buttonLabel: t('homepage.add_cart'),
      price: 'Rp 9.000.000',
      description: ['12 Tiktok Videos (with Phone Camera)', '12 Instagram Reels (Mirroring TikTok)', '15 Instagram Feeds (Caption & Hashtag)', '2x Video Sessions', '1x Photoshoot ']
    },
    {
      title: "Relevant Package",
      image: background_list.sila7,
      onClick: () => {
        window.fbq('track', 'Add To Cart', {content_name: router.route})
        router.push(route_list.register)
      },
      buttonLabel: t('homepage.add_cart'),
      price: 'Rp 6.000.000',
      description: [
        '12 Tiktok Videos (with Phone Camera)',
        '2x Video Session'
      ]
    },
    {
      title: "Impactful Package",
      image: background_list.sila2,
      onClick: () => {
        window.fbq('track', 'Add To Cart', {content_name: router.route})
        router.push(route_list.register)
      },
      buttonLabel: t('homepage.add_cart'),
      price: 'Rp 7.000.000',
      description: [
        '12 Tiktok Videos (with Phone Camera)',
        '12 Instagram Reels (Mirroring TikTok)',
        '4 Instagram Feeds (Caption & Hashtag)',
        '2x Video Session',
        '1x Photoshoot'
      ]
    },
    {
      title: "Premium Relevant Package",
      image: background_list.sila3,
      onClick: () => {
        window.fbq('track', 'Add To Cart', {content_name: router.route})
        router.push(route_list.register)
      },
      buttonLabel: t('homepage.add_cart'),
      price: 'Rp 9.000.000',
      description: [
        '12 Tiktok Videos (with Lens Camera)',
        'Lens Camera'
      ]
    },
    {
      title: "Premium Impactful Package",
      image: background_list.sila4,
      onClick: () => {
        window.fbq('track', 'Add To Cart', {content_name: router.route})
        router.push(route_list.register)
      },
      buttonLabel: t('homepage.add_cart'),
      price: 'Rp 10.000.000',
      description: [
        '12 Tiktok Videos (with Lens Camera)',
        '12 Instagram Reels (Mirroring TikTok)',
        '4 Instagram Feeds',
        'Lens Camera',
        '1x Photoshoot'
      ]
    },
  ]

  const stepData = [
    {
      title: t('homepage.step_1'),
      description: t('homepage.step_1_desc')
    },
    {
      title: t('homepage.step_2'),
      description: t('homepage.step_2_desc')
    },
    {
      title: t('homepage.step_3'),
      description: t('homepage.step_3_desc')
    },
    {
      title: t('homepage.step_4'),
      description: t('homepage.step_4_desc')
    }
  ]

  const whyChooseData = {
    title: t('homepage.why_choose'),
    description: t('homepage.why_choose_desc'),
    image: background_list.why_social_bread_hp,
    points: [
      {
        title: t('homepage.p1'),
        description: t('homepage.p1_desc')
      },
      {
        title: t('homepage.p2'),
        description: t('homepage.p2_desc')
      },
      {
        title: t('homepage.p3'),
        description: t('homepage.p3_desc')
      },
      {
        title: t('homepage.p4'),
        description: t('homepage.p4_desc')
      },
    ]
  }

  const dataRecognition = [
    {
      year: "2023",
      title: "TOP 39 Startup in SEA (South East Asia)",
      name: "TechinAsia"
    },
    {
      year: "2023",
      title: "Verified Tiktok Shop Partner (TSP) of 2023",
      name: "Tiktok Shop Partner"
    },
    {
      year: "2023",
      title: "2nd ranking for highest GMV on Ramadhan sale",
      name: "Tiktok Service Provider"
    }
  ]

  // useEffect(() => {
  //   console.log("trackCustom")
  //   window.fbq('track', 'VisitPage', {content_name: 'Homepage'})
  // }, [])

  // console.log(props)
	return (
		<div className='tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green tw-w-full'>
      <MetaData title={props.seoMetadata.title} ogImage="https://i.ibb.co/bzC5z1g/socialbreadlogo.jpg" ogTitle={props.seoMetadata.title} keywords={props.seoMetadata.keywords} ogDescription={props.seoMetadata.description} />
			<div className='tw-newsroom-banner tw-relative' id='newsroom-banner'>
				<div className='tw-m-auto md:tw-py-16 tw-relative tw-z-[1] tw-py-8 md:tw-px-0 tw-px-4 tw-max-w-6xl tw-mx-auto tw-grid md:tw-grid-cols-2 tw-gap-2'>
					<div>
            <h2 className='tw-text-white md:tw-text-5xl tw-text-2xl tw-font-semibold tw-mb-5 md:tw-text-left tw-text-center'>
              { t('homepage.title')}
            </h2>
            <span className='tw-text-white tw-text-sm md:tw-text-justify tw-flex tw-text-center'>
              { t('homepage.description')}
            </span>
            <div className='md:tw-flex tw-hidden tw-items-end tw-flex-row tw-gap-2 tw-mt-5'>
              <StyledButton onClick={() => window.open(app_config.AndroidClientAppUrl)} className=''>
                <Image
                  alt=''
                  src={icon_list.playstore_logo}
                  className='tw-cursor-pointer tw-h-12 tw-w-[150px]'
                />
              </StyledButton>
              <StyledButton onClick={() => window.open(app_config.AppstoreClientAppUrl)} className=''>
                <Image
                  alt=''
                  src={icon_list.appstore_logo}
                  className='tw-cursor-pointer tw-h-12 tw-w-[150px]'
                />
              </StyledButton>
            </div>
          </div>
          <div className='tw:w-full tw-flex tw-flex-col tw-items-center tw-justify-center'>
            <Image src={background_list.homepage_icon } alt='' className='md:tw-w-[80%] tw-w-[50%]' />
            <div className='md:tw-hidden tw-flex tw-items-end tw-flex-row tw-gap-2 tw-mt-5'>
              <StyledButton onClick={() => window.open(app_config.AndroidClientAppUrl)} className=''>
                <Image
                  alt=''
                  src={icon_list.playstore_logo}
                  className='tw-cursor-pointer tw-h-12 tw-w-[150px]'
                />
              </StyledButton>
              <StyledButton onClick={() => window.open(app_config.AppstoreClientAppUrl)} className=''>
                <Image
                  alt=''
                  src={icon_list.appstore_logo}
                  className='tw-cursor-pointer tw-h-12 tw-w-[150px]'
                />
              </StyledButton>
            </div>
          </div>
				</div>
        <Image
          alt=''
          src={background_list.wave_upper}
          className='tw-w-full tw-absolute tw-bottom-[-35px] tw-object-cover md:tw-h-auto tw-h-[200px]'
        />
			</div>
			<div className='tw-w-full tw-min-h-[500px] tw-flex tw-items-center tw-justify-center tw-relative tw-z-10 tw-flex-col tw-bg-[#F1F1F1] md:tw-rounded-[40px] tw-rounded-[20px] tw-overflow-hidden'>
				<div className='tw-newsroom-container'>
					<div className='tw-max-w-6xl tw-mx-auto tw-mt-8 tw-mb-20 md:tw-px-0 tw-px-4 tw-text-justify'>
            <h1 className='md:tw-text-5xl tw-text-2xl tw-font-semibold tw-mb-10 tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-60% tw-text-center tw-pb-1'>{t('homepage.our_service')}</h1>
            <div className=' tw-mb-12'>
              <h2 className='md:tw-text-2xl tw-text-xl tw-font-semibold tw-mb-10 tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-60% tw-text-center'>{t('homepage.smm')}</h2>
              <p className='tw-max-w-xl tw-m-auto tw-text-center tw-my-10'>
                {t('homepage.our_service_desc')}
              </p>
                <PackageSwiperBig data={swiperData} titleClass="tw-text-center"/>
            </div>
            {/* Live shopping */}
            <div className='tw-m-auto md:tw-px-10 tw-mb-10'>
              <h2 className='md:tw-text-2xl tw-text-xl tw-font-semibold tw-mb-10 tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-60% tw-text-center'>LIVE SHOPPING</h2>
              <p className='tw-max-w-3xl tw-m-auto tw-text-center tw-mb-10'>
                {t('homepage.live_shopping_desc')}
              </p>
              <div className='tw-w-full tw-rounded-2xl tw-relative tw-overflow-hidden tw-grid md:tw-grid-cols-3  md:tw-py-14 tw-py-5 md:tw-px-20 tw-px-5'>
                <Image src={background_list.card_wave_green_big} alt="arrow rotate" className='tw-absolute tw-top-0 tw-right-0 tw-z-0 tw-h-full' />
                <div className='tw-z-10 md:tw-col-span-2 '>
                  <h2 className='tw-text-white md:tw-text-5xl tw-text-2xl tw-font-semibold tw-mb-5 md:tw-text-left tw-text-center'>
                    {t('homepage.our_package')}
                  </h2>
                  <ol className='tw-text-white tw-gap-4 tw-mb-5 tw-flex-col tw-flex tw-list-disc tw-max-w-md tw-pl-6 tw-text-left'>
                    <li>{t('homepage.li1')}</li>
                    <li>{t('homepage.li2')}</li>
                    <li>{t('homepage.li3')}</li>
                    <li>{t('homepage.li4')}</li>
                    <li>{t('homepage.li5')}</li>
                    <li>{t('homepage.li6')}</li>
                    <li>{t('homepage.li7')}</li>
                  </ol>
                  <span className='tw-mt-5 tw-text-white'>{t('homepage.start_from')}</span>
                  <div className='tw-text-white tw-flex tw-flex-row md:tw-items-center tw-text-start'>
                    <h1 className='md:tw-text-3xl tw-text-md tw-font-semibold'>Rp 13.000.000</h1>
                    <span className='tw-ml-1'>({t('homepage.after_tax')})</span>
                  </div>
                </div>
                <div className='tw-w-full tw-items-center tw-justify-center tw-z-10 tw-flex tw-flex-col'>
                  <Image src={background_list.DSelfie } alt='' className='md:tw-block tw-hidden' />
                  <StyledButton
                    title={t('homepage.contact_us')}
                    className='tw-text-white tw-mt-5 tw-font-semibold md:tw-text-xl tw-text-xs tw-items-center tw-border-2 tw-border-white md:tw-px-6 tw-px-3 md:tw-py-2 tw-py-1 tw-rounded-full tw-justify-center md:tw-flex'
                    onClick={() => window.open(app_config.whatsappTemplate)}
                  />
                </div>
              </div>
            </div>
            {/* Endorsement */}
            <div className='tw-m-auto md:tw-px-10 tw-mb-10'>
              <h2 className='md:tw-text-2xl tw-text-xl tw-font-semibold tw-mb-10 tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-60% tw-text-center tw-uppercase'>{t('homepage.endorsement_byi')}</h2>
              <p className='tw-max-w-3xl tw-m-auto tw-text-center tw-mb-10'>
                {t('homepage.endorsement_desc')}
              </p>
              <div className='tw-w-full tw-flex tw-justify-center'>
                <StyledButton
                  title={t('homepage.learn_more')}
                  className='btn-dark-to-light-green-gradient tw-text-white tw-font-semibold md:tw-text-md md:tw-text-md tw-text-sm tw-items-center md:tw-px-12 tw-px-5 md:tw-py-4 tw-py-2 tw-rounded-full md:tw-flex '
                  onClick={() => router.push(route_list.endorsement)}
                />
              </div>
            </div>
            {/* Who we are */}
            <CardWithDescription data={our_section}/>
            {/* How It Works */}
            <div className='md:tw-my-32'>
              <h1 className='md:tw-text-5xl tw-text-2xl tw-pb-1 tw-font-semibold tw-mb-5 tw-mt-10 tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-60% tw-text-left md:tw-px-5 tw-w-fit'>{t('homepage.how_it_works')}</h1>
              <p className='tw-text-left md:tw-px-5 tw-mb-5'>
                {t('homepage.how_it_works_desc')}
              </p>
              <div className="tw-flex tw-items-center tw-font-poppins tw-rounded-lg ">
                <div className="tw-justify-center tw-flex-1 tw-max-w-6xl md:tw-px-5">
                  <CardPointToPoint data={stepData} />
                </div>
              </div>
            </div>
            {/* Why Choose  Social Bread? */}
            <div className=' tw-mt-5'></div>
            <TitleImageDescSection data={whyChooseData}/>
            {/* Achievements */}
            <div className='tw-m-auto md:tw-px-5'>
              <div className='tw-w-full tw-overflow-hidden tw-grid tw-rounded-lg tw-mb-10'>
                <div className='md:tw-px-0 md:tw-pt-0 tw-pt-5'>
                  <h1 className='md:tw-text-5xl tw-text-2xl tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-60% tw-text-left tw-w-fit'>{t('homepage.achievements')}</h1>
                </div>
                <div className='tw-w-full tw-z-10 tw-flex tw-flex-col tw-py-5 tw-rounded-lg'>
                  <p className='tw-text-left'>
                    {t('homepage.achievements_desc')}
                  </p>
                </div>
              </div>
              <div className='tw-w-full tw-overflow-hidden tw-grid md:tw-grid-cols-2 tw-rounded-lg tw-gap-5'>
                <div className='tw-grid tw-grid-cols-2 tw-gap-5'>
                  <div className='tw-bg-gradient-to-r tw-from-dark-green tw-from-0% tw-to-light-green tw-to-100% tw-opacity-70 tw-p-5 tw-rounded-2xl tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2'>
                    <span className='md:tw-text-5xl tw-text-md tw-font-semibold tw-text-white'>
                      200+
                    </span>
                    <span className='md:tw-text-sm tw-text-xs tw-text-white tw-max-w-[50px]'>
                      {t('homepage.a_1')}
                    </span>
                  </div>
                  <div className='tw-bg-gradient-to-r tw-from-dark-green tw-from-0% tw-to-light-green tw-to-100% tw-opacity-70 tw-p-5 tw-rounded-2xl tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2'>
                    <span className='md:tw-text-5xl tw-text-md tw-font-semibold tw-text-white'>
                      150+
                    </span>
                    <span className='md:tw-text-sm tw-text-xs tw-text-white md:tw-max-w-[70px] tw-max-w-[60px] tw-text-left'>
                      {t('homepage.a_2')}
                    </span>
                  </div>
                  <div className='tw-bg-gradient-to-r tw-from-dark-green tw-from-0% tw-to-light-green tw-to-100% tw-opacity-70 tw-p-5 tw-rounded-2xl tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2'>
                    <span className='md:tw-text-5xl tw-text-md tw-font-semibold tw-text-white'>
                      100+
                    </span>
                    <span className='md:tw-text-sm tw-text-xs tw-text-white tw-max-w-[50px]'>
                      {t('homepage.a_3')}
                    </span>
                  </div>
                  <div className='tw-bg-gradient-to-r tw-from-dark-green tw-from-0% tw-to-light-green tw-to-100% tw-opacity-70 tw-p-5 tw-rounded-2xl tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2'>
                    <span className='md:tw-text-5xl tw-text-md tw-font-semibold tw-text-white'>
                      30+
                    </span>
                    <span className='md:tw-text-sm tw-text-xs tw-text-white tw-max-w-[100px] tw-text-left'>
                      {t('homepage.a_4')}
                    </span>
                  </div>
                </div>
                <div className='tw-w-full tw-flex tw-flex-col tw-rounded-lg tw-gap-5'>
                  <div className='tw-flex tw-flex-col bg-yellow-green tw-px-8 tw-rounded-xl tw-py-5 tw-text-white'>
                    <span className='tw-mr-5'>{t('homepage.a_5')}</span>
                    <div className='tw-flex tw-flex-row tw-items-center'>
                      <h1 className='tw-mr-3 tw-text-3xl tw-font-bold'>IDR. 5.900.000.000</h1>
                      <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.5 0L25 13.3333H0L12.5 0Z" fill="white"/>
                      </svg>
                    </div>
                    <span className='tw-mr-5 tw-mt-10'>Growth vs. 2022</span>
                    <div className='tw-flex tw-flex-row tw-items-center'>
                      <h1 className='tw-mr-5 tw-text-3xl tw-font-bold'>65.80%</h1>
                      <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.5 0L25 13.3333H0L12.5 0Z" fill="white"/>
                      </svg>
                    </div>
                    <span className='tw-text-end tw-text-sm tw-mt-10'>{t('homepage.a_6')}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* BUSINESS PARTNERS WITH LEADING PLATFORMS */}
            <div className="tw-flex tw-items-center tw-font-poppins tw-rounded-lg tw-mb-4 tw-mt-24">
							<div className="tw-justify-center tw-flex-1 tw-max-w-6xl tw-pt-4 tw-mx-auto md:tw-px-6">
								<h2 className="tw-mb-10 md:tw-text-3xl tw-text-xl tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-70% tw-text-center">BUSINESS PARTNERS WITH LEADING PLATFORMS</h2>
                <div className="tw-flex md:tw-flex-row tw-flex-col tw-gap-10 tw-items-center tw-justify-center tw-mt-10">
                    <div className="tw-w-full md:tw-w-1/3 md:tw-mb-0 tw-rounded-xl tw-flex tw-justify-center">
                      <Image src={icon_list.tiktok_logo } alt='' />
                    </div>
                    <div className="tw-w-full md:tw-w-1/3 md:tw-mb-0 tw-rounded-xl tw-flex tw-justify-center">
                      <Image src={icon_list.tiktok_partner } alt='' />
                    </div>
                    <div className="tw-w-full md:tw-w-1/3 md:tw-mb-0 tw-rounded-xl tw-flex tw-justify-center">
                      <Image src={icon_list.shopee_logo } alt='' />
                    </div>
                    <div className="tw-w-full md:tw-w-1/3 md:tw-mb-0 tw-rounded-xl tw-flex tw-justify-center">
                      <Image src={icon_list.shopee_partner } alt='' />
                    </div>
                </div>
							</div>
						</div>
            {/* In the press */}
						<div className="tw-flex tw-items-center tw-font-poppins tw-rounded-lg tw-mb-4 tw-mt-24">
							<div className="tw-justify-center tw-flex-1 tw-max-w-6xl tw-pt-4 tw-mx-auto md:tw-px-6">
								<h2 className="tw-mb-10 md:tw-text-3xl tw-text-xl tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-70% tw-text-center">IN THE PRESS</h2>
                <div className="tw-flex md:tw-flex-row tw-flex-col tw-gap-10 tw-items-center tw-justify-center tw-my-10">
                    <div className="tw-w-full md:tw-w-1/3 md:tw-mb-0 tw-rounded-xl tw-flex tw-justify-center">
                      <Image src={icon_list.investor } alt='' />
                    </div>
                    <div className="tw-w-full md:tw-w-1/3 md:tw-mb-0 tw-rounded-xl tw-flex tw-justify-center">
                      <Image src={icon_list.idntimes } alt='' />
                    </div>
                    <div className="tw-w-full md:tw-w-1/3 md:tw-mb-0 tw-rounded-xl tw-flex tw-justify-center">
                      <Image src={icon_list.jawa_pos } alt='' />
                    </div>
                    <div className="tw-w-full md:tw-w-1/3 md:tw-mb-0 tw-rounded-xl tw-flex tw-justify-center">
                      <Image src={icon_list.sinar_harapan } alt='' />
                    </div>
                </div>
								<div className="tw-inline-flex tw-items-center tw-justify-center tw-w-full">
									<hr className="tw-w-[300px] tw-h-[1px] tw-my-8 tw-bg-dark-green tw-border-0 tw-rounded" />
									<div className="tw-absolute tw-px-4 -tw-translate-x-1/2 tw-bg-[#F1F1F1] tw-left-1/2">
										<h2 className="md:tw-text-xl tw-text-sm tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green tw-text-center">And many more</h2>
									</div>
								</div>
							</div>
						</div>
            {/* Recognition */}
						<div className="tw-flex tw-items-center tw-font-poppins tw-rounded-lg tw-mb-4 tw-mt-24">
							<CardRecognitions data={dataRecognition} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Homepage

export async function getServerSideProps({ locale = 'id' }: any) {
  const seoMetadata = await getSeoMetadata({category_slug: 'pages', names: 'homepage'})

	return {
		props: {
      seoMetadata: seoMetadata.data.result || {},
			...(await serverSideTranslations(locale, ['footer', 'navbar', 'privacy-policy'])),
		},
	}
}
